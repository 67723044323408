import {makeRedirectPage} from '@app-components/pages/RedirectPage';
import React from 'react';


interface PageProps {
}

export default makeRedirectPage<PageProps>(
  (router, props) => {
    router.push('/dashboard');
  },
);
