import {MainLayout} from '@app-components/layouts/MainLayout';
import {NextComponentType} from 'next';
import {NextPageContext} from 'next/dist/next-server/lib/utils';
import {
  NextRouter,
  useRouter,
} from 'next/router';
import React, {useEffect} from 'react';


export function makeRedirectPage<PageProps>(
  sendTo: (router: NextRouter, props: PageProps) => void,
  watchProps: (props: PageProps) => Array<any> = defaultWatchProps,
): NextComponentType<NextPageContext, PageProps, PageProps> {
  return function Page(props: PageProps) {
    if (typeof window === 'undefined') {
      return null;
    }
    const router = useRouter();
    useEffect(
      () => {
        if (router) {
          sendTo(router, props);
        }
      },
      [router, ...watchProps(props)],
    );

    return (
      <MainLayout padding>
        <div />
      </MainLayout>
    );
  };
}

function defaultWatchProps<PageProps>() {
  return [];
}
